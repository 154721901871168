.TGAMenu
{
  /*margin: 5px 5px 5px 5px;*/
  border-top: none;
  border-bottom: none;
  border-left: none;
  border: 2px solid #2224261a;
  width: 75vw;
  margin-bottom: 64px;
}

a.TGAMenuTopItem
{
  color: black;
  text-decoration: inherit;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  padding: 18px 10px 2px 10px;
  border-top: none;
  border-bottom: none;
  border-left: none;
  height: 61px;
  border-right: 2px solid #2224261a;
  background: none;
}

a.TGAMenuTopItemimg
{
  color: black;
  text-decoration: inherit;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  padding: 10px 10px 10px 10px;
  border-top: none;
  border-bottom: none;
  border-left: none;
  height: 61px;
  border-right: 2px solid #2224261a;
  background: none;
}

.TGAMenuTopItem
{
  color: black;
  text-decoration: inherit;
  vertical-align:top;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  padding: 10px 10px 10px 10px;
  border-top: none;
  border-bottom: none;
  border-left: none;
  height: 61px;
  border-right: 2px solid #2224261a;
  background: none;
}

.TGAMenuTopButton
{
  color: black;
  text-decoration: inherit;
  vertical-align:top;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  padding: 0px 10px 10px 10px;
  border-top: none;
  border-bottom: none;
  border-left: none;
  height: 61px;
  border-right: 2px solid #2224261a;
  background: none;
}

.TGAMenuTopButtonText
{
  padding-bottom: 5px;
}
.TGAMenuTop
{
  vertical-align:top;
  display: inline-block;
  font-size: 16px;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  border: none;
  background: none;
}

.TGAMenuTopItem:hover
{
  background-color: #e5e5e5;
}

.QuarterlyMenu
{
  color: black;
  text-decoration: inherit;
}

.TGAMenuArrow
{
  font-size: 16px;
  margin-Left: 4px;
  vertical-align:middle;
}
/* CSS button styles */
.button
{
	display: inline-block;
	zoom: 1; /* zoom and *display = ie7 hack for display:inline-block */
	*display: inline;
	vertical-align: baseline;
	margin: 0 2px;
	outline: none;
	cursor: pointer;
	text-align: center;
	text-decoration: none;
	font: 14px/100% Arial, Helvetica, sans-serif;
	padding: .5em 2em .55em;
	text-shadow: 0 1px 1px rgba(0,0,0,.3);
	-webkit-border-radius: .5em;
	-moz-border-radius: .5em;
	border-radius: .5em;
	-webkit-box-shadow: 0 1px 2px rgba(0,0,0,.2);
	-moz-box-shadow: 0 1px 2px rgba(0,0,0,.2);
	box-shadow: 0 1px 2px rgba(0,0,0,.2);
}
