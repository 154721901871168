.smalltextinput{
  width: 70%;
  margin-left: 10%;
  margin-right: 50%;
  margin-top: 1%;
  margin-bottom: 1%;
  padding-top: 1%;
  padding-bottom: 1%;
  display: inline-block;
}

.largetextinput{
  width: 70%;
  margin-left: 10%;
  margin-right: 50%;
  margin-top: 1%;
  margin-bottom: 1%;
  padding-top: 1%;
  padding-bottom: 1%;
  height: 12em;
  vertical-align: top;
  display: inline-block;
}

.captchabutton{
  width: 70%;
  display: inline-block;
  margin-left: 10%;
  margin-right: 50%;
  padding-top: 1%;
  padding-bottom: 1%;
}

.formstatus{
  width: 70%;
  display: inline-block;
  margin-left: 10%;
  margin-right: 50%;
  padding-top: 1%;
  padding-bottom: 1%;
}

.formstatustext{
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.formbox{
  width: 80%;
  margin-left: 5%;
  margin-right: 55%;
  border-style: solid;
  border-radius: 15px;
}

.resetbutton{
  display: inline-block;
  margin-left: 10%;
  margin-right: 50%;
  margin-top: 1%;
  margin-bottom: 1%;
}
