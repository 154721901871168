.largeTextField {
    width: 70%;
    margin-left: 0%;
    margin-right: 50%;
    margin-top: 1%;
    margin-bottom: 1%;
    padding-top: 1%;
    padding-bottom: 1%;
    height: 2em;
    vertical-align: top;
    display: inline-block;
}

.smallTextField {
    width: 70%;
    margin-left: 0%;
    margin-right: 50%;
    margin-top: 1%;
    margin-bottom: 1%;
    padding-top: 1%;
    padding-bottom: 1%;
    height: 2em;
    vertical-align: top;
    display: inline-block;
}

.smallInput {
    width: 70%;
    margin-left: 0%;
    margin-right: 50%;
    margin-top: 1%;
    margin-bottom: 1%;
    padding-top: 1%;
    padding-bottom: 1%;
    height: 0.25em;
    vertical-align: top;
    display: inline-block;
}

.buttonPrimary{
    border-style:solid; 
    border-width:1px; 
    border-radius:8px; 
    padding:0px 8px 0px 8px;
    margin: 0px 4px 0px 4px;
    align-items:center;
    justify-content:center; 
    cursor:default;
    background-color: lightgrey;
    font: inherit;
    height:40px;
    min-width:80px;
}

.buttonSecond{
    border-style:solid; 
    border-width:1px; 
    border-radius:8px; 
    padding:0px 8px 0px 8px;
    margin: 0px 4px 0px 4px;
    align-items:center;
    justify-content:center; 
    cursor:default;
    background-color: #ae2515;
    color: white;
    font: inherit;
    height:40px;
    min-width:80px;
}