.react-select-container {
  display:flex;
  align-items:center;
  flex-grow: 1;
}

.react-select-control {
  flex-grow: 1;
}

.react-select-input {
  
}

.venue-select-label {
  font:inherit;
  margin: 0px 4px 4px 4px;
}

.venue-editor-layout {
  display: grid;
  grid-template-rows: 1fr 5fr;
  row-gap: 8px;
  grid-template-columns: minmax(0,1fr) 1fr;
  column-gap: 8px;
}

.venue-editor-controls-item {
  grid-column: 1 / 3;
  grid-row: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap:8px
}

/* .venue-editor-form-item {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  display:grid;
  row-gap: 8px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
} */

.venue-editor-form-item {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  display:flex;
  flex-direction: column;
  gap: 8px;
}


.venue-editor-map-item {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-color: rgb(204, 204, 204);
  border-width: 1px;
  border-radius: 8px;
}

.venue-editor-button {
  display:flex; 
  border-style:solid; 
  border-width:1px; 
  border-radius:8px; 
  padding:0px 8px 0px 8px;
  align-items:center;
  justify-content:center; 
  cursor:default;
  background-color: lightgrey;
  font: inherit;
  height:40px
}
