.event-form {
  display:grid; 
  grid-template-columns: 3fr 2fr;
  grid-template-rows:50px 50px 50px 1fr 50px;
  gap: 8px;
  justify-items: center;
  align-items: center;
  height:100%;
}

.event-form-first-row-container {
  grid-row-start: 1;
  grid-row-end:2;
  grid-column-start: 1;
  grid-column-end: 3;
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-form-second-row-container {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end:3;
  justify-content: center;
  align-items: center;
  gap:8px;
  display: flex;
  height: 24px;
  width: 100%;
}

.event-form-third-row-container {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end:4;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap:8px;
  display: flex;
  height: 24px;
  width: 100%;
}

.controls-row {
  display:flex;
  height:30px;
  gap:8px;
  justify-content: space-evenly;
  align-items: center;
}

.event-form-recurrence-controls {
  grid-column-start: 1;
  grid-column-end: 3;
  justify-content: space-evenly;
  gap:8px;
  display: flex;
  height: 24px;
  align-items: center;
  width: 100%;
}

.event-form-fields {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 4;
  grid-row-end: 5;
  display:flex;
  flex-grow: 1;
  flex-direction: column;
  place-self: stretch;
  gap: 8px;
}

.event-form-venue-select-container{
  width:100%;
}

.event-form-venue {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 4;
  grid-row-end: 5;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap:8px;
  place-self: stretch;
  height:100%;
}

.event-form-venue-placeholder {
  container-type: size;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:100%;
  width:100%;
  flex-grow: 1;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: lightgray;
}

.event-form-venue-placeholder-image {
  object-fit: scale-down;
  height:75cqh;
  width:75cqw;
}

.event-form-increment-button {
  height:40px;
  width:40px;
  margin: 0 8px 0 8px;
  font-size: large;
  border-radius: 8px;
}

.event-form-controls {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 5;
  grid-row-end: 6;
  display: flex;
  justify-self: flex-end;
  gap:8px;
}

.event-form-item {
  padding: 4px;
  border-radius: 4px;
  font: inherit;
  resize: none;
  border-color: rgb(204, 204, 204);
  border-width: 1px;
}

.event-form-venue-map {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: lightgray;
}

.helper-text {
  margin: 0px 0px 4px 8px;
  font:inherit;
  font-size: small;
  color:rgb(204, 0, 0);
}

.event-form-button {
  display:flex; 
  border-style:solid; 
  border-width:1px; 
  border-radius:8px; 
  padding:0px 8px 0px 8px;
  align-items:center;
  justify-content:center; 
  cursor:default;
  background-color: lightgrey;
  font: inherit;
  height:40px;
  min-width:80px;
}

.read-only-field {
  background-color:#f5f7f6;
  padding:8px;
  border-radius:4px;
}

.leaflet-control { z-index: 0 !important}
.leaflet-pane { z-index: 0 !important}
.leaflet-top, .leaflet-bottom {z-index: 0 !important}