#create-event-button {
  margin-top: 8px;
  display:flex; 
  border-style:solid; 
  border-width:1px; 
  border-radius:8px; 
  padding:0px 8px 0px 8px;
  align-items:center;
  justify-content:center; 
  cursor:default;
  background-color: lightgrey;
  font: inherit;
  height:40px;
  width:90%;
}
