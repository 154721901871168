body
{
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}





.navbar
{
	/*margin-left: auto;
	margin-right: auto;*/
  font-size: 16px;
}





.containergama
{
  /*background-color: #fe0000;*/
	background-color: #fcfcfc;
	margin-bottom: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	width: 75vw;
}

img.quart-logo
{
  display: block;
  max-width:100%;
  max-height:100%;
}

img.kids_item
{
  width: 200px;
  height: 200px;
  float: right;
}


.gamaMenuTop
{
  display: inline-block;
  font-size: 16px;
  margin: -13px 0px -13px 0px;
  padding: 13px 0px 13px 0px;
  border: none;
  background: none;
}





.gamaMenuTopItem
{
  display: inline-block;
  font-size: 16px;
  padding: 10px 10px 10px 10px;
  border-top: none;
  border-bottom: none;
  border-left: none;
  border-right: 2px solid #2224261a;
  background: none;
}





.gamaMenuTopItem:hover
{
  background-color: #e5e5e5;
}





.gamaMenu
{
  /*margin: 5px 5px 5px 5px;*/
  border-top: none;
  border-bottom: none;
  border-left: none;
  border: 2px solid #2224261a;
  width: 75vw;
}





.gamaMenuArrow
{
  margin-Left: 4px;
  vertical-align:middle;
}










.infoBar
{
  border-radius: 15px;
  margin-top: 10px;
  margin-right: 0px;
  margin-left: auto;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-right: 10px;
  padding-left: 0px;
  background-color: #e5e5e5;
  width: 50vmin;
}





.infoDate
{
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 25px;
  font-size: 20px;
}





.infoVenue
{
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 25px;
  font-size: 16px;
  display: block;
}





.landing
{
  display: flex;
  justify-content: center;
  /*margin: 0 auto;
  width: 50%;*/
}





img.eventMap
{
  width: 75vw;
}





.EmbeddedMap
{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top:20px;
  border:0;
}





/* CSS button styles */
.button
{
	display: inline-block;
	zoom: 1; /* zoom and *display = ie7 hack for display:inline-block */
	display: inline;
	vertical-align: baseline;
	margin: 0 2px;
	outline: none;
	cursor: pointer;
	text-align: center;
	text-decoration: none;
	font: 14px/100% Arial, Helvetica, sans-serif;
	padding: .5em 2em .55em;
	text-shadow: 0 1px 1px rgba(0,0,0,.3);
	-webkit-border-radius: .5em;
	-moz-border-radius: .5em;
	border-radius: .5em;
	-webkit-box-shadow: 0 1px 2px rgba(0,0,0,.2);
	-moz-box-shadow: 0 1px 2px rgba(0,0,0,.2);
	box-shadow: 0 1px 2px rgba(0,0,0,.2);
}





.button:hover
{
	text-decoration: none;
}





.button:active
{
	position: relative;
	top: 1px;
}





.green
{
	color: #e8f0de;
	border: solid 1px #538312;
	background: #64991e;
	background: -webkit-gradient(linear, left top, left bottom, from(#7db72f), to(#4e7d0e));
	background: -moz-linear-gradient(top,  #7db72f,  #4e7d0e);
	filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#7db72f', endColorstr='#4e7d0e');
}





.green:hover
{
	background: #538018;
	background: -webkit-gradient(linear, left top, left bottom, from(#6b9d28), to(#436b0c));
	background: -moz-linear-gradient(top,  #6b9d28,  #436b0c);
	filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#6b9d28', endColorstr='#436b0c');
}





.green:active
{
	color: #fcd3a5;
	background: -webkit-gradient(linear, left top, left bottom, from(#4e7d0e), to(#7db72f));
	background: -moz-linear-gradient(top,  #4e7d0e,  #7db72f);
	filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#4e7d0e', endColorstr='#7db72f');
}





.red
{
	color: #faddde;
	border: solid 1px #980c10;
	background: #d81b21;
	background: -webkit-gradient(linear, left top, left bottom, from(#ed1c24), to(#aa1317));
	background: -moz-linear-gradient(top,  #ed1c24,  #aa1317);
	filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#ed1c24', endColorstr='#aa1317');
}





.red:hover
{
	background: #b61318;
	background: -webkit-gradient(linear, left top, left bottom, from(#c9151b), to(#a11115));
	background: -moz-linear-gradient(top,  #c9151b,  #a11115);
	filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#c9151b', endColorstr='#a11115');
}





.red:active
{
	color: #de898c;
	background: -webkit-gradient(linear, left top, left bottom, from(#aa1317), to(#ed1c24));
	background: -moz-linear-gradient(top,  #aa1317,  #ed1c24);
	filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#aa1317', endColorstr='#ed1c24');
}










/* registration styles */
#registration_container span.registration
{
	text-align: center;
	float: right;
}





#registration_sub_container span.registration
{
	width: 250px;
	padding: 25px 20px 20px 20px;
	border-style: solid;
	border-width: thin;
	margin: 0px 25px 0px 25px;
	text-align: center;
	float:right;
}





img.registration_item
{
	width: 200px;
	height: 200px;
	border-style: solid;
	border-width: 1px;
	margin:0 auto;
}





img.registration_button
{
	width: 20px;
	height: 20px;
	vertical-align: middle;
}


















ol, ul {
  padding-left: 30px;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: flex;
  flex-direction: row;
}

.game-info {
  margin-left: 20px;
}

.buttonlink {
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial;
  font-size: 16px;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}
