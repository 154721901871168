@import '~leaflet/dist/leaflet.css';

.venue-map-container{
  height: 98%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: lightgray;
  margin-top: 4px;
}